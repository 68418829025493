import React from 'react'
import Img from "gatsby-image";
import AppStoreButtons from "./app-store-buttons";

const AppStoreBanner = ({data: { appStoreImage, playStoreImage, appScreenShotImage}}) => (
  <div className="app_store_banner">
    <strong>Get the App</strong>
    {
      typeof appScreenShotImage !== 'undefined'
      ? <div className="store_buttons_screenshot"><Img fixed={appScreenShotImage.childImageSharp.fixed} style={{marginBottom: 10}} alt="Camping Checklist App screenshot" title="Camping Checklist App screenshot" /></div>
      : null
    }
    <AppStoreButtons data={{appStoreImage, playStoreImage}} />
  </div>
)

export default AppStoreBanner