import React, { useState } from 'react'
import SEO from '../seo'
import {graphql, StaticQuery} from "gatsby";
import ChecklistList from "../checklist-list";
import {getWebViewParam} from "../../utilities";
import * as constants from "../../constants";
import {STORAGE_STRINGS} from "../../constants";
import AppStoreBanner from "../app-store-banner";

const RecommendedPageComponent = ({embedded, webViewVersion, showAllContent}) => {
  let searchFromStorage;
  if (typeof window !== 'undefined' && window.localStorage) {
    searchFromStorage = window.localStorage.getItem(STORAGE_STRINGS.SEARCH);
  }
  // what's in the search input field
  const [search, setSearch] = useState(searchFromStorage || '');
  // what's actually applied to the search results
  const [searchApplied, setSearchApplied] = useState(searchFromStorage || '');
  // what's actually applied to filtering by tags
  const [tagsApplied, setTagsApplied] = useState([]);
  const [isStorageTagsApplied, setIsStorageTagsApplied] = useState(false);
  let allTags;

  const getChecklistData = (strapiChecklistData) => {
    const checklistData = [];
    strapiChecklistData.edges.forEach((document) => {
      checklistData.push(document.node);
    });
    return checklistData;
  }

  const getAllTags = (checklistData) => {
    const tags = [];
    checklistData.forEach((checklist) => {
      if (checklist.tags && checklist.tags.length > 0) {
        checklist.tags.forEach((tag) => {
          if (!tags[tag.id]) {
            tags[tag.id] = tag;
          }
        })
      }
    })
    return tags;
  }

  const isTagApplied = (id) => {
    return tagsApplied.indexOf(id) !== -1;
  }

  // const getChecklistJumpLinks = (checklistData) => {
  //   const checklistJumpLinks = [];
  //   let index = 0;
  //   checklistData.forEach((checklist) => {
  //     checklistJumpLinks.push(<span key={`jump_link_${checklist.Title}`}>{index++ > 0 ? ' | ': ''}<a href={`#${checklist.Title}`}>{checklist.Title}</a></span>);
  //   })
  //   return <div className="checklist_jump_links">Camping Gear: {checklistJumpLinks}</div>;
  // }

  const getChecklistTagFilters = (allChecklistTags) => {
    allTags = allChecklistTags.slice(0);
    const checklistTagFilterLinks = [];
    allChecklistTags.forEach((tag) => {
      const tagApplied = isTagApplied(tag.id);
      checklistTagFilterLinks.push(
        <button className={`checklist_tag_button${(tagApplied ? ' checklist_tag_on': '')}`} onClick={handleApplyTag} id={`tag_${tag.id}`} key={`tag_${tag.id}`}>
          <span>{tagApplied ? '✓' : '+'}</span> {tag.Name}
        </button>
      );
    })
    return <div className="checklist_filters">
      <div className="checklist_filter_links">{checklistTagFilterLinks}<br />
        {!embedded ?
          <div>
            <span><button className="button_link" onClick={handleClearTags}>clear all</button></span>
            <span><button className="button_link" onClick={handleCheckAllTags}>check all</button></span>
          </div>
          : null}
      </div>
    </div>;
  }

  const contentMatch = (fieldsToMatch, searchApplied) => {
    let foundMatch = false;
    fieldsToMatch.forEach((fieldContent) => {
      if (fieldContent && fieldContent !== ''
        && (fieldContent.toLowerCase().includes(searchApplied.toLowerCase()) || searchApplied.toLowerCase().includes(fieldContent.toLowerCase()))) {
        foundMatch = true;
      }
    })
    return foundMatch;
  }

  const getChecklistFilteredByTag = (checklistData) => {
    if (tagsApplied.length === 0) {
      return checklistData;
    } else {
      const filteredChecklists = [];
      checklistData.forEach((checklist) => {
        let hasBeenAdded = false;
        if (checklist.tags && checklist.tags.length > 0) {
          checklist.tags.forEach((tag) => {
            if (isTagApplied(tag.id) && !hasBeenAdded) {
              filteredChecklists.push((checklist));
              hasBeenAdded = true;
            }
          })
        }
      })
      return filteredChecklists;
    }
  }

  const getChecklistWithFilteredRecommendations = (checklistData) => {
    if (searchApplied === '') {
      return checklistData;
    } else {
      const filteredChecklists = [];
      // go through all checklists, check if they still have recommendations
      checklistData.forEach((checklist) => {
        // go through all recommendations, check if it should be filtered, if not added it to filteredRecommendations
        if (checklist.recommendations && checklist.recommendations.length > 0) {
          const filteredRecommendations = [];
          checklist.recommendations.forEach((recommendation) => {
            const fieldsToMatch = [
              recommendation.Title, recommendation.Body,
              recommendation.Content1, recommendation.Heading1,
              recommendation.Content2, recommendation.Heading2,
              recommendation.Content3, recommendation.Heading4
            ];
            // TODO: filter out public!=false somewhere else
            if (contentMatch(fieldsToMatch, searchApplied)) {
              filteredRecommendations.push(recommendation);
            }
          });
          // if all recommendations haven't been filtered out, add the filtered list of the checklist
          //  and add that checklist to the filtered checklists
          if (filteredRecommendations.length > 0) {
            checklist.recommendations = filteredRecommendations;
            filteredChecklists.push(checklist);
          } else {
            // also check the details of the checklist for text matching
            const fieldsToMatch = [
              checklist.Title, checklist.Description
            ];
            if (contentMatch(fieldsToMatch, searchApplied)) {
              filteredChecklists.push(checklist);
            }
          }
        }
      });
      return filteredChecklists;
    }
  }

  const getFilteredChecklists = (checklistData) => {
    // apply tag filtering
    const tagFilteredChecklist = getChecklistFilteredByTag(checklistData.slice(0));
    // apply search filtering
    const searchFilteredChecklist = getChecklistWithFilteredRecommendations(tagFilteredChecklist.slice(0));
    return searchFilteredChecklist;
  }

  const handleApplyTag = (event) => {
    try {
      const id = parseInt(event.target.id.substr(4), 10);
      const tags = tagsApplied.length > 0 ? tagsApplied.slice(0) : [];
      // add tag id if not in tags, otherwise remove it from tags
      const indexOfTag = tags.indexOf(id);
      if (indexOfTag === -1) { // need to add it
        tags.push(id);
      } else { // need to remove it
        tags.splice(indexOfTag, 1);
      }
      setTagsApplied(tags.length > 0 ? tags.slice(0): []);
      window.localStorage.setItem(STORAGE_STRINGS.TAGS, tags.join(','));
    } catch (error) {
      console.error(error);
    }
  }

  const handleClearTags = () => {
    setTagsApplied([]);
    window.localStorage.removeItem(STORAGE_STRINGS.TAGS);
  }

  const handleCheckAllTags = () => {
    const appliedTags = [];
    allTags.forEach((tag) => {
      appliedTags.push(tag.id);
    })
    setTagsApplied(appliedTags.slice(0));
    window.localStorage.setItem(STORAGE_STRINGS.TAGS, appliedTags.join(','));
  }

  const applyStorageTags = () => {
    let initialTags = [];
    try {
      const tagsFromStorage = window.localStorage.getItem(STORAGE_STRINGS.TAGS);
      if (tagsFromStorage !== '' && tagsFromStorage !== null) {
        const tagStrings = tagsFromStorage.split(',');
        initialTags = tagStrings.map((tag) => {
          return parseInt(tag);
        });
      } else {
        initialTags = [1];
      }
    } catch(e) {
      // just use defaults
    }
    setTagsApplied(initialTags.slice(0));
  }

  const handleApplySearch = (event) => {
    setSearchApplied(search);
    window.localStorage.setItem(STORAGE_STRINGS.SEARCH, search);
    event.preventDefault();
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const handleSearchClear = () => {
    setSearch('')
    setSearchApplied('')
    window.localStorage.removeItem(STORAGE_STRINGS.SEARCH);
  }

  return (
    <StaticQuery
      query={graphql`
        query ChecklistsQuery {
          allStrapiChecklists {
            edges {
              node {
                id
                Title
                Icon
                Description
                recommendations {
                  id
                  Item
                  Public
                  Body
                  Content1
                  Content2
                  Content3
                  Heading1
                  Heading2
                  Heading3
                  forceHasDetails
                }
                tags {
                  id
                  Icon
                  Name
                }
              }
            }
          }
          playStore: file(relativePath: { eq: "google-play.png" }) {
            childImageSharp {
              fixed(width: 192, height: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          appStore: file(relativePath: { eq: "app-store.png" }) {
            childImageSharp {
              fixed(width: 192, height: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          appScreenShot: file(relativePath: { eq: "cc-app-track-items.png" }) {
            childImageSharp {
              fixed(width: 192, height: 382) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        const appStoreImage = data.appStore;
        const playStoreImage = data.playStore;
        const appScreenShotImage = data.appScreenShot;
        const checklistData = getChecklistData(data.allStrapiChecklists);
        const allChecklistTags = getAllTags(checklistData);
        // apply tag and search filter
        const filteredChecklistData = getFilteredChecklists(checklistData.slice(0));
        let nativeAppBuildNumber;
        if (embedded) {
          // NOTE: see constants.NATIVE_QUERY_PARAMS for other params available
          nativeAppBuildNumber = getWebViewParam(constants.NATIVE_QUERY_PARAMS.BuildNumber);
        }
        if (isStorageTagsApplied === false) {
          setTimeout(applyStorageTags, 10);
          setIsStorageTagsApplied(true);
        }
        const hideDescription = searchApplied !== '';
        return (
          <div>
            <SEO title="Recommended gear for camping" description="Camping gear recommendations, tips on how to use them best, and where you can buy them" />
            <a id="top" />
            {!embedded ? <div className="banner_wrapper"><AppStoreBanner data={{ appStoreImage, playStoreImage, appScreenShotImage }} /></div> : null}
            {!embedded ? <h1>Camping Gear Recommendations</h1> : null}
            {getChecklistTagFilters(allChecklistTags)}
            <form onSubmit={handleApplySearch}>
              <input type="text" value={search} onChange={handleSearchChange} className="search_input" />
              <input type="submit" value="Search" className="button primary_button" />
            </form>
            {/*{getChecklistJumpLinks(checklistData)}*/}
            {searchApplied !== '' ? <h4>Search Results for "{searchApplied}" <button className="button secondary_button" onClick={handleSearchClear}>X</button></h4> : null}
            <RecommendationContent checklistData={filteredChecklistData} metaData={{hideDescription, embedded, webViewVersion, nativeAppBuildNumber, showAllContent}} />
          </div>
        )
      }
    } />
  )
}

const RecommendationContent = ({checklistData, metaData: { hideDescription, embedded, webViewVersion, nativeAppBuildNumber, showAllContent}}) => {
  const [isChecklistsClosedApplied, setIsChecklistsClosedApplied] = useState(false);
  const [checklistsClosed, setChecklistsClosed] = useState([]);

  const getRecommendationContent = (checklistData, embedded) => {
    const recommendationContent = [];
    checklistData.forEach((checklist) => {
      const id = parseInt(checklist.id.substr(11), 10);
      const closed = checklistsClosed.indexOf(id) !== -1;
      if (checklist.recommendations.length > 0) {
        recommendationContent.push(
          <ChecklistList data={{checklist, hideDescription, embedded, webViewVersion, closed, handleToggleChecklist, nativeAppBuildNumber, showAllContent}} key={`checklist_${checklist.id}`} />
        )
      }
    })
    return recommendationContent.length > 0 ? recommendationContent : <div>No results.  Please try your search again.</div>;
  }

  const handleToggleChecklist = (idString) => {
    try {
      const id = parseInt(idString.substr(11), 10);
      const checklistsClosedCopy = checklistsClosed.slice(0);
      // add tag id if not in tags, otherwise remove it from tags
      const indexOfChecklist = checklistsClosed.indexOf(id);
      if (indexOfChecklist === -1) { // need to add it
        checklistsClosedCopy.push(id);
      } else { // need to remove it
        checklistsClosedCopy.splice(indexOfChecklist, 1);
      }
      setChecklistsClosed(checklistsClosedCopy);
      window.localStorage.setItem(STORAGE_STRINGS.CHECKLISTS_CLOSED, checklistsClosedCopy.join(','));
    } catch (error) {
      console.error(error);
    }
  }

  const expandAll = () => {
    setChecklistsClosed([]);
    window.localStorage.removeItem(STORAGE_STRINGS.CHECKLISTS_CLOSED);
  }

  const collapseAll = () => {
    const checklistsClosed = [];
    checklistData.forEach((checklist) => {
      const id = parseInt(checklist.id.substr(11), 10);
      checklistsClosed.push(id);
    });
    setChecklistsClosed(checklistsClosed);
    window.localStorage.setItem(STORAGE_STRINGS.CHECKLISTS_CLOSED, checklistsClosed.join(','));
  }

  const applyChecklistsClosed = () => {
    let initialChecklistClosed = [];
    try {
      const checklistClosedFromStorage = window.localStorage.getItem(STORAGE_STRINGS.CHECKLISTS_CLOSED);
      if (checklistClosedFromStorage !== '' && checklistClosedFromStorage !== null) {
        const checklistClosedStrings = checklistClosedFromStorage.split(',');
        initialChecklistClosed = checklistClosedStrings.map((closed) => {
          return parseInt(closed);
        });
      } else {
        initialChecklistClosed = [];
      }
    } catch(e) {
      // just use defaults
    }
    setChecklistsClosed(initialChecklistClosed.slice(0));
  }

  if (isChecklistsClosedApplied === false) {
    setTimeout(applyChecklistsClosed, 10);
    setIsChecklistsClosedApplied(true);
  }

  return (
    <div>
      <div style={{marginBottom: 20}}>
        <button className="button tertiary_button" onClick={collapseAll}>collapse all</button> <button className="button tertiary_button" onClick={expandAll}>expand all</button>
      </div>
      {getRecommendationContent(checklistData, embedded)}
    </div>
  )
}

export default RecommendedPageComponent