import React from "react";
import queryString from "query-string";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EMOJI_MAP} from "./constants";

export const isEmbedded = () => {
  if (typeof window !== 'undefined' && window.location.search !== '') {
    const params = queryString.parse(window.location.search);
    if (params && params.embedded && params.embedded === 'true') {
      return true;
    }
  }
  return false;
}

export const isTest = () => {
  // TODO: check url string or cookie
  // return true;
  if (typeof window !== 'undefined' && window.location.search !== '') {
    const params = queryString.parse(window.location.search);
    if (params && params.istest && params.istest === 'true') {
      return true;
    }
  }
  return false;
}

export const prependFa = (icon) => {
  const iconUpper = icon.charAt(0).toUpperCase() + icon.slice(1);
  return 'fa' + iconUpper;
}

export const getIconWidget = (iconString, iconSize, iconStyle, emojiStyle, showEmoji=false, showDefault=true) => {
  const prependedIcon = prependFa(iconString);
  if (typeof faIcons[prependedIcon] !== 'undefined') {
    return <FontAwesomeIcon icon={faIcons[prependedIcon]} size={iconSize} style={iconStyle} />;
  } else if (showEmoji && typeof EMOJI_MAP[iconString] !== 'undefined') {
    return <span style={emojiStyle}>{EMOJI_MAP[iconString]}</span>;
  } else if (showDefault) {
    return <FontAwesomeIcon icon={faIcons['faAsterisk']} size={iconSize} style={iconStyle} />;
  } else {
    return null;
  }
}

export const getWebViewParam = (paramConstant) => {
  try {
    if (typeof window !== 'undefined' && window.location.search !== '') {
      const params = queryString.parse(window.location.search);
      if (params && params[paramConstant]) {
        return params[paramConstant];
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}