import React from 'react'
import Img from "gatsby-image";
import {URLS} from "../constants";

const AppStoreButtons = ({data: { appStoreImage, playStoreImage}}) => (
  <span className="store_buttons">
    <a href={URLS.APPLE} target="_blank"><Img fixed={appStoreImage.childImageSharp.fixed} alt="Download Camping Checklist from the App Store" title="Download GearPacCamping ChecklistkingChecklist from the App Store" /></a>
    <a href={URLS.GOOGLE} target="_blank"><Img fixed={playStoreImage.childImageSharp.fixed} alt="Download Camping Checklist from the Google Play" title="Download Camping Checklist from the Google Play" /></a>
  </span>
)

export default AppStoreButtons