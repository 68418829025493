const urlFriendlyTitle = (title) => {
  let newTitle = title;
  newTitle = newTitle.toLowerCase();
  newTitle = newTitle.replace(/ |\//g, '-', );
  newTitle = newTitle.replace(/,|:/g, '', );
  return newTitle;
}

// NOTE: anytime the string includes {sometext} return what's before it
const removeCurlyMetaData = (item) => {
  let updatedItem = item;
  try {
    const start = item.indexOf('{');
    const end = item.indexOf('}');
    if (start !== -1 && end !== -1 && end > start) {
      updatedItem = item.slice(0, start).trim();
    }
  } catch (e) {
    // just return original string
  }
  return updatedItem;
}

module.exports = {
  urlFriendlyTitle: urlFriendlyTitle,
  removeCurlyMetaData: removeCurlyMetaData
}