export const NATIVE_QUERY_PARAMS = {
  PackageName: 'PackageName', // ex: com.stellarjaysoftware.gearchecklist.dev || com.stellarjaysoftware.gearchecklist
  PackageVersion: 'PackageVersion', // ex: 1.0.7
  BuildNumber: 'BuildNumber', // ex: 7
  OperatingSystem: 'OperatingSystem' // ex: android | ios
}

export const FEATURE_MIN_VERSIONS = {
  addChecklistButton: 7
}

export const STORAGE_STRINGS = {
  TAGS: 'tagsApplied',
  SEARCH: 'searchApplied',
  CHECKLISTS_CLOSED: 'checklistsClosed',
  ENABLE_ALL_RECOMMENDED: 'enable-all-recommended'
}

export const EMOJI_MAP = {
  "emoji:climbing": "🧗"
}

export const URLS = {
  APPLE: "https://apps.apple.com/us/app/id1520345192",
  GOOGLE: "https://play.google.com/store/apps/details?id=com.stellarjaysoftware.gearchecklist"
}