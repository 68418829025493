import React from "react"
// import { graphql } from "gatsby"
// import LinkMaintainEmbedded from "../../components/LinkMaintainEmbedded";
import showdown from "showdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTree,
  faBullseye,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";
import {
  faAmazon
} from "@fortawesome/free-brands-svg-icons";
import pluralize from "pluralize";
import AppStoreBanner from "../app-store-banner";
const formatter = require('../../../utilities/formatter');

const RecommendationTemplateComponent = function ({ recommendation, embedded, webViewVersion, isTest, showAllContent=false, storeImages: {appStoreImage, playStoreImage, appScreenShotImage }}) {
  const converter = new showdown.Converter();
  const body = converter.makeHtml(recommendation.Body);
  const content1 = recommendation.Content1 !== null ? converter.makeHtml(recommendation.Content1) : null;
  const content2 = recommendation.Content2 !== null ? converter.makeHtml(recommendation.Content2) : null;
  const content3 = recommendation.Content3 !== null ? converter.makeHtml(recommendation.Content3) : null;

  // const getProductImage = (url, title) => (<img border="0" src={url} alt={title} title={title} />)

  const getAmazonTrackingImage = (url) => (<img src={url} width="1" height="1" border="0" alt="" style={{border:"none !important", margin: 0+" !important"}} />)

  const openProductUrl = (url) => {
    if (typeof window.Product !== 'undefined') {
      window.Product.postMessage(url);
    } else {
      window.open(url, '_gpcProductWindow');
    }
  }

  const openResourceUrl = (url) => {
    if (typeof window.Resource !== 'undefined') {
      window.Resource.postMessage(url);
    } else {
      window.open(url, '_gpcProductWindow');
    }
  }

  const getIcon = (url) => {
    if (url.indexOf('target.com') !== -1) {
      return <FontAwesomeIcon icon={faBullseye} />;
    } else if (url.indexOf('amazon.com') !== -1) {
      return <FontAwesomeIcon icon={faAmazon} />;
    } else if (url.indexOf('rei.com') !== -1) {
      return <FontAwesomeIcon icon={faTree} />;
    } else {
      return <FontAwesomeIcon icon={faExternalLinkAlt} />;
    }
  }

  const handleBack = () => {
    window.history.back();
  }

  return (
    <div>
      {!embedded
        ? <div className="banner_wrapper">
            <AppStoreBanner data={{ appStoreImage, playStoreImage, appScreenShotImage }} />
          </div>
        : null }
      <p>
        {showAllContent === false &&
          <button onClick={handleBack} className="button tertiary_button back_button" style={{marginRight: 15}}>
            &lt;&nbsp;Back
          </button>
        }
      </p>
      <h2>{formatter.removeCurlyMetaData(recommendation.Item)}</h2>
      {/*this should be ok, because I'm going to be publishing and entirely static website*/}
      <p dangerouslySetInnerHTML={{__html: body}} />
      {(recommendation.Heading1 && recommendation.Heading1 !== '') ? <h3>{recommendation.Heading1}</h3> : null}
      {(content1 && content1 !== '') ? <p dangerouslySetInnerHTML={{__html: content1}} /> : null}
      {(recommendation.Heading2 && recommendation.Heading2 !== '') ? <h3>{recommendation.Heading2}</h3> : null}
      {(content2 && content2 !== '') ? <p dangerouslySetInnerHTML={{__html: content2}} /> : null}
      {(recommendation.Heading3 && recommendation.Heading3 !== '') ? <h3>{recommendation.Heading3}</h3> : null}
      {(content3 && content3 !== '') ? <p dangerouslySetInnerHTML={{__html: content3}} /> : null}

      {
        recommendation.Products && recommendation.Products.length > 0
          ? (<h3 style={{fontSize: 'inherit'}} data-test="product_header">Recommended {pluralize(recommendation.Item)}</h3>) : ''
      }

      <ul className="product_list">
        {recommendation.Products && recommendation.Products.length > 0 && recommendation.Products.map(product => {
          if (product.Public === true) {
            const maxLength = 40;
            const shortTitle = product.Title.length > maxLength ? product.Title.substring(0, maxLength-3) + '...' : product.Title;
            const icon = getIcon(product.Url);
            return (
              <li key={product.id}>
                {
                  embedded
                    ? <button className="button product_button" onClick={() => openProductUrl(product.Url)}>{shortTitle} {icon}</button>
                    : <a className="button product_button" href={product.Url} target={isTest ? "_self" : "_blank"  } rel="noopener noreferrer">{shortTitle} {icon}</a>
                }
                {product.Amazon_tracking_img?getAmazonTrackingImage(product.Amazon_tracking_img):''}
              </li>
            )
          } else {
            return null
          }
        })}
      </ul>

      {
        recommendation.Resources && recommendation.Resources.length > 0
          ? (<h3 style={{fontSize: 'inherit'}} data-test="resource_header">Resources</h3>) : ''
      }

      <ul className="resource_list">
        {recommendation.Resources && recommendation.Resources.length > 0 && recommendation.Resources.map(resource => {
          if (resource.Public === true) {
            const maxLength = 40;
            const shortTitle = resource.Title.length > maxLength ? resource.Title.substring(0, maxLength-3) + '...' : resource.Title;
            const icon = getIcon(resource.Url);
            return (
              <li key={resource.id}>
                {
                  embedded
                    ? <button className="button resource_button" onClick={() => openResourceUrl(resource.Url)}>{shortTitle} {icon}</button>
                    : <a className="button resource_button" href={resource.Url} target="_blank" rel="noopener noreferrer">{shortTitle} {icon}</a>
                }
              </li>
            )
          } else {
            return null
          }
        })}
      </ul>
    </div>
  )}

export default RecommendationTemplateComponent