import React from 'react'
import Layout from '../components/layout'
import RecommendedPageComponent from "../components/pages/recommended";

const RecommendedPage = () => {
  return (
    <Layout embedded={false}>
      <RecommendedPageComponent embedded={false} />
    </Layout>
  )
}

export default RecommendedPage
