import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import LinkMaintainEmbedded from "./LinkMaintainEmbedded";
import showdown from "showdown";
import {getIconWidget} from "../utilities";
import * as constants from "../constants";
import RecommendationTemplateComponent from "./templates/recommendation";
const formatter = require('../../utilities/formatter');
const content = require('../../utilities/content');

const ChecklistList = ({data: {checklist, hideDescription, embedded, webViewVersion, closed, handleToggleChecklist, nativeAppBuildNumber, showAllContent}}) => {
  const converter = new showdown.Converter();

  const getRecommendationLink = (recommendation, embedded, webViewVersion) => {
    const fieldsToCheck = ['Body', 'Heading1', 'Content1', 'Heading2', 'Content2', 'Heading3', 'Content3'];
    const hasContent = recommendation.forceHasDetails === true || content.hasContent(recommendation, fieldsToCheck);
    return (
      <li key={recommendation.id}>
        {
          hasContent
          ? <LinkMaintainEmbedded to={`/recommended/${formatter.urlFriendlyTitle(recommendation.Item)}`} embedded={embedded}
                              webViewVersion={webViewVersion} className="recommended">{formatter.removeCurlyMetaData(recommendation.Item)}</LinkMaintainEmbedded>
          : recommendation.Item
        }
      </li>
    )
  }

  const getRecommendationContent = (recommendation, embedded, webViewVersion) => {
    const fieldsToCheck = ['Body', 'Heading1', 'Content1', 'Heading2', 'Content2', 'Heading3', 'Content3'];
    const hasContent = recommendation.forceHasDetails === true || content.hasContent(recommendation, fieldsToCheck);
    return (
      <li key={recommendation.id}>
        {
          hasContent
            ? <RecommendationTemplateComponent recommendation={recommendation} showAllContent={showAllContent} embedded={embedded} webViewVersion={webViewVersion} />
            : <h2>{formatter.removeCurlyMetaData(recommendation.Item)}</h2>
        }
      </li>
    )
  }

  const addChecklist = (id) => {
    if (id && typeof window.Checklist !== 'undefined') {
      window.Checklist.postMessage(id);
    } else {
      console.warn('feature not enabled');
    }
  }

  const description = converter.makeHtml(checklist.Description);

  const iconStyle = {marginRight: 10, minWidth: '1.25em', color: '#145A37'};
  const emojiStyle = {marginRight: 10, minWidth: '1.25em', color: 'transparent', textShadow: '0 0 0 #145A37'};
  const iconSize = "1x";
  // NOTE: if user isn't on latest version turn this feature off so I don't need to worry about backward compatibility
  const showAddChecklist = embedded && nativeAppBuildNumber !== null
    && nativeAppBuildNumber >= constants.FEATURE_MIN_VERSIONS.addChecklistButton;
  return (
    <div>
      <a id={checklist.Title} />
      <h2 onClick={() => {
        handleToggleChecklist(checklist.id);
      }} className="checklist_title">
        {/*<FontAwesomeIcon icon={!closed ? faIcons.faChevronDown : faIcons.faChevronRight} size="xs" style={{marginRight: !closed ? 10 : 15 }} />*/}
        {checklist.Icon ? getIconWidget(checklist.Icon, iconSize, iconStyle, emojiStyle, true) : null}
        <span className="title">{checklist.Title}</span>
      </h2>
      {/* only show descriptions when not search results */}
      {
        !closed ?
          <div style={{marginBottom: 20, marginLeft: 20}}>
            {(!hideDescription && !embedded ? <div className="checklist_description" dangerouslySetInnerHTML={{__html: description}} /> : '')}
            <a href="#top" style={{display: "inline-block", float: "right"}}>top</a>
            <ul className="checklist_items">
              {!showAllContent
                ? checklist.recommendations.map(recommendation => getRecommendationLink(recommendation, embedded, webViewVersion))
                : checklist.recommendations.map(recommendation => getRecommendationContent(recommendation, embedded, webViewVersion))
              }
            </ul>
            {
              showAddChecklist === true
              ? <div style={{paddingBottom: 10}}>
                  <button onClick={() => addChecklist(checklist.id)}
                          className="button product_button"><FontAwesomeIcon icon={faIcons.faPlus} size="sm" /> Add this checklist</button>
                </div>
              : null
            }
          </div>
          : null
      }

    </div>
  );
}

export default ChecklistList;