const hasContent = (contentObject, fieldsToCheck) => {
  let foundContent = false;
  fieldsToCheck.forEach((field) => {
    if (contentObject && contentObject[field] && (contentObject[field] !== null || contentObject[field].trim() !== '')) {
      foundContent = true;
    }
  })
  return foundContent;
}

module.exports = {
  hasContent: hasContent
}